const DIGIT = '9'
const ALPHA = 'A'
const ALPHANUM = 'S'
export const Formatters = {
  methods: {
    formatMask: (value, type, mask = false) => {
      let pattern = mask

      if (!mask) {
        switch (type) {
          case 'CEP':
            pattern = '99999-999'
            break
          case 'PHONE':
            pattern = '(99) 99999-9999'
            break
          case 'CPF':
            pattern = '999.999.999-99'
            break
          case 'CNPJ':
            pattern = '99.999.999/9999-99'
            break
        }
      }
      // All the credits of this function goes to https://github.com/vanilla-masker/vanilla-masker
      // I was searching for something to use mask on an Label. I found this Lib but I couldn't make it run no Vue properly
      // So I changed his code to work on my context, Thanks Vanilla Masker - Fernando Fleury
      if (!pattern && !mask) return value
      const patternChars = pattern.replace(/\W/g, '')
      const output = pattern.split('')
      const values = value.toString().replace(/\W/g, '')
      const charsValues = values.replace(/\W/g, '')
      let index = 0
      let i
      const outputLength = output.length

      for (i = 0; i < outputLength; i++) {
        if (index >= values.length) {
          if (patternChars.length === charsValues.length) {
            return output.join('')
          } else {
            break
          }
        } else {
          if ((output[i] === DIGIT && values[index].match(/[0-9]/)) ||
            (output[i] === ALPHA && values[index].match(/[a-zA-Z]/)) ||
            (output[i] === ALPHANUM && values[index].match(/[0-9a-zA-Z]/))) {
            output[i] = values[index++]
          } else if (output[i] === DIGIT || output[i] === ALPHA || output[i] === ALPHANUM) {
            return output.slice(0, i).join('')
          }
        }
      }
      return output.join('').substr(0, i)
    }
  }
}
