<script>
import { Formatters } from '@/mixins/formatters'
export default {
  name: 'AttendanceList',
  components: {
    Action: () => import('@/components/general/Action'),
    Datatable: () => import('@/components/general/Datatable'),
    Checkbox: () => import('@/components/general/Checkbox')
  },
  mixins: [Formatters],
  props: {
    studentsList: {
      type: Array,
      default: () => []
    },
    hasMore: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      yesNoOptions: [
        { label: this.$t('global:yes'), value: true },
        { label: this.$t('global:no'), value: false }
      ]
    }
  },
  created () {
    if (this.getContextPermission('session_attendance') !== 'write') {
      this.$router.push({ name: 'sessions.index' })
      this.setFeedback({ message: this.t('global.error.permission') })
    }
  },
  methods: {
    requestMore () {
      this.$emit('loadMore')
    }
  }
}
</script>
<template>
  <div class="attendance-list">
    <p
      class="attendance-warning"
      v-html="$t('session.manage.attendance:students.info')"
    />
    <datatable
      :items="studentsList"
      hide-horizontal-line
    >
      <template slot="thead">
        <th class="th">
          {{ $t('session.manage.attendance:students.info.name') }}
        </th>
        <th
          class="th text-center"
          width="200"
        >
          {{ $t('session.manage.attendance:students.info.cpf') }}
        </th>
        <th
          class="th text-center"
          width="300"
        >
          {{ $t('session.manage.attendance:students.info.enrollment') }}
        </th>
        <th
          class="th text-center"
          width="100"
        >
          {{ $t('session.manage.attendance:students.info.attendance') }}
        </th>
      </template>
      <template
        slot="tbody"
        slot-scope="props"
      >
        <tr>
          <td>
            <span class="td-text bolder">{{ props.item.user.name }}</span>
          </td>
          <td class="text-center bolder">
            <span class="td-text">{{ formatMask(props.item.user.username, 'CPF') }}</span>
          </td>
          <td class="text-center">
            <span class="td-text">{{ formatDateFromJS(props.item.createdAt).slice(0, 10) }}</span>
          </td>
          <td class="td td-button td-small text-center">
            <checkbox
              v-model="props.item.attendance"
              :tooltip="props.item.status === 'finished' ? $t('session.manage.attendance:student.already.finished') : false"
              :items="[{value: 1}]"
              switch-type
              :disabled="props.item.status === 'finished'"
            />
          </td>
        </tr>
      </template>
    </datatable>
    <action
      v-if="hasMore"
      type="button"
      :text="$t('global:load.more.students')"
      secondary
      flat
      @click="requestMore"
    />
  </div>
</template>
<style>
.attendance-warning {
  margin: 0 auto 40px;
  text-align: center;
  color: #0c0f56;
}
.load-more-actions {
  width: 100%;
  text-align: center;
}
</style>
